<main>
  <div
    class="loader"
    [ngStyle]="{
      height: diameter + 'px',
      width: diameter + 'px',
      'border-width': thickness + 'px',
    }"
  ></div>
</main>
